<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <form @submit.prevent="handleSubmit">
        <div class="p-fluid p-grid">
          <h2>{{ $t("customer.new.title") }}</h2>
          <div class="p-field p-col-12">
            <p>{{ $t("customer.new.text") }}</p>
          </div>
          <div class="p-field p-col-12">
            <label for="company">{{ $t("customer.new.field_company") }}</label>
            <InputText
              id="company"
              v-model="customer.company"
              aria-label="company"
              aria-describedby="company-error"
              :class="{ 'p-invalid': !!errors['company'] }"
            />
            <small
              v-if="!!errors['company']"
              id="company-error"
              class="p-error"
              role="alert"
              aria-live="polite"
              >{{ $t("customer.new.field_company_error") }}</small
            >
          </div>
          <div class="p-field p-col-12">
            <label for="first-name">{{
              $t("customer.new.field_contact_first_name")
            }}</label>
            <InputText
              id="first-name"
              v-model="customer.contact.name"
              aria-label="first-name"
              aria-describedby="first-name-error"
              :class="{ 'p-invalid': !!errors['name'] }"
            />
            <small
              v-if="!!errors['name']"
              id="first-name-error"
              class="p-error"
              role="alert"
              aria-live="polite"
              >{{ $t("customer.new.field_contact_first_name_error") }}</small
            >
          </div>
          <div class="p-field p-col-12">
            <label for="surname">{{
              $t("customer.new.field_contact_surname")
            }}</label>
            <InputText
              id="surname"
              v-model="customer.contact.surname"
              aria-label="surname"
              aria-describedby="surname-error"
              :class="{ 'p-invalid': !!errors['surname'] }"
            />
            <small
              v-if="!!errors['surname']"
              id="surname-error"
              class="p-error"
              role="alert"
              aria-live="polite"
              >{{ $t("customer.new.field_contact_surname_error") }}</small
            >
          </div>
          <div class="p-field p-col-12">
            <label for="email">{{
              $t("customer.new.field_contact_email")
            }}</label>
            <InputText
              id="email"
              v-model="customer.contact.email"
              aria-label="email"
              aria-describedby="email-error"
              :class="{ 'p-invalid': !!errors['email'] }"
            />
            <small
              v-if="!!errors['email']"
              id="email-error"
              class="p-error"
              role="alert"
              aria-live="polite"
              >{{ $t("customer.new.field_contact_email_error") }}</small
            >
          </div>
        </div>
        <div class="p-d-flex p-jc-end">
          <Button type="submit" data-testid="submit">
            {{ $t("common.register") }}
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import { AVAILABLE_LOCALES, TOAST_LIFE } from "../config";
import Customer from "../models/Customer";
import { assertEmail, assertEmpty } from "../service/assert";
import { REGISTER_CUSTOMER } from "../store/modules/customer/customer.actions.type";

export default {
  name: "FormNewCustomer",
  components: { Button, InputText },
  setup() {
    const store = useStore();
    const toast = useToast();
    const { t } = useI18n();
    const errors = ref([]);
    const customer = ref(Customer.empty());
    const availableLocates = AVAILABLE_LOCALES;

    async function handleSubmit() {
      if (validateInputs()) {
        await store.dispatch(REGISTER_CUSTOMER, customer.value);
        toast.add({
          severity: "success",
          summary: t("common.success"),
          detail: t("customer.new.toast_success"),
          life: TOAST_LIFE,
        });
        cleanForm();
      }
    }

    const cleanForm = () => {
      customer.value = Customer.empty();
    };

    function validateInputs() {
      errors.value = [];
      if (assertEmpty(customer.value.company)) {
        errors.value["company"] = true;
      }
      if (assertEmpty(customer.value.contact.name)) {
        errors.value["name"] = true;
      }
      if (assertEmpty(customer.value.contact.surname)) {
        errors.value["surname"] = true;
      }
      if (!assertEmail(customer.value.contact.email)) {
        errors.value["email"] = true;
      }
      return Object.keys(errors.value).length === 0;
    }

    return {
      availableLocates,
      customer,
      errors,
      handleSubmit,
    };
  },
};
</script>
